import React from 'react'

import FAQ from 'src/components/Faq'

import { FaqStyledSection } from './style'

type pageProps = {
  lang: string;
  inputPlaceholder?: string;
  pageContext: {
    slug: string;
    metaTitle: string;
    metaDescription: string;
    hideBabiChat: boolean;
    structuredData: {
      faq: {
        question: string;
        answer: string;
      }[];
    };
  };
}

const FAQSection = ({ lang, pageContext, inputPlaceholder }: pageProps) => {
  return (
    <FaqStyledSection className='py-5 bg-white'>
      <div className='container'>
        <div className='row'>
          <div className='mb-4'>
            <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-0'>
              {lang === 'pt' ? 'Perguntas frequentes' : 'FAQ'}
            </h3>
          </div>
          <FAQ
            id='investments' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='summary-content px-0'
            answerData={pageContext.structuredData.faq}
            inputPlaceholder={inputPlaceholder}
          />
        </div>
      </div>
    </FaqStyledSection>
  )
}

export default FAQSection
