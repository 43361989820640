import React from 'react'
import { HeroSection } from './style'

import textPT from '../../assets/data/_hero/textPT.json'
import textEN from '../../assets/data/_hero/textEN.json'
import FormularioInvestments from 'src/components/FormularioInvestments'

import { GlobalContext, IGlobalContext } from 'src/context/GlobalContext'

const Hero = (props: {lang: string}) => {
  const pageText = props.lang === 'pt' ? textPT : textEN

  const { setHeaderColorContrast }: IGlobalContext = React.useContext(GlobalContext)

  React.useEffect(() => {
    const handleHeaderColor = () => {
      setHeaderColorContrast(true)
    }

    handleHeaderColor()
  }, [])

  return (
    <HeroSection className='bg-grayscale--400 py-5'>
      <div className='container mt-lg-5'>
        <div className='row'>
          <div className='col-12 col-md-8 col-lg-6 col-xl-5 offset-md-4 offset-lg-6 offset-xl-7 mt-lg-4'>
            <h1 className='fs-32 lh-40 fs-xl-40 lh-xl-50 f-sora text-grayscale--500 mb-4' dangerouslySetInnerHTML={{ __html: pageText.title }} />
            <div>
              <FormularioInvestments pageText={props.lang} language={pageText.form} />
            </div>
          </div>
        </div>
      </div>
    </HeroSection>
  )
}

export default Hero
