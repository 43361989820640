import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'

import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import { Modal } from 'src/components/Modal'

import Security from 'inter-frontend-svgs/lib/orangeds/MD/security'

import { sendDataLayerEvent } from 'src/shared/helpers'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import useDomReady from 'src/hooks/window/useDomReady'

import { Container, Checkbox, CloseButton, WhatsappGreenButton, PrefixDisabledInputContainer } from './style'
import { IFormValues, FormFields } from './types'

import {
  Error,
  Filled,
  Sent,
} from './status/_index'
import { grayscale } from 'src/styles/colors'

interface IInputLang {
  label: string;
  placeholder: string;
  errorMessage: string;
}

interface IFormResponseLang {
  title: string;
  descrpition: string;
  confirm: string;
}

interface IFormLang {
  nameInput: IInputLang;
  emailInput: IInputLang;
  prefixInput: IInputLang;
  phoneInput: IInputLang;
  acceptTerms: string;
  submitButton: {
    loading: string;
    text: string;
  };
  error: IFormResponseLang;
  sent: IFormResponseLang;
}

type IFormularioInvestments = {
  pageText: string;
  language: IFormLang;
};

const FormularioInvestments = ({ pageText, language }: IFormularioInvestments) => {
  const [ isPopupOpen, setIsPopupOpen ] = useState(false)
  const [ sendDatalayerEvents ] = useDataLayer()

  const handleClose = () => {
    setIsPopupOpen(false)
  }
  const isDomReady = useDomReady()

  const AmericanPhoneMask = (phoneNumber: string | null) => {
    const phone = phoneNumber && phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
    if (phone) {
      phoneNumber = phone && !phone[2] ? phone[1] : '(' + phone[1] + ') ' + phone[2] + (phone[3] ? '-' + phone[3] : '')
      return phoneNumber
    }
    return null
  }

  const closeButton = handleClose && (
    <CloseButton className='border-0 bg-transparent pt-3 pt-md-4' onClick={handleClose}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  const {
    register,
    errors,
    handleSubmit,
    setValue,
  }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const formStatus = {
    idle: 'idle',
    loading: 'loading',
    sent: 'sent',
    error: 'error',
    filled: 'filled',
    underAge: 'underAge',
  }

  const [ accept, setAccept ] = useState(false)
  const [ status, setStatus ] = useState(formStatus.idle)

  const sendForm = async (data: IFormValues) => {
    setStatus(formStatus.loading)

    const formData = {
      campanha: 'Investimentos Internacional',
      nome: data.name,
      email: data.email,
      telefone: data.telefone.replace(/\D/g, ''),
      conteudo01: '1',
      conteudo02: pageText.toUpperCase(),
      aceite: true,
    }

    const title = document.getElementById('formTitle')

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      sendDataLayerEvent('form_submitted')
      sendDatalayerEvents({
        section: 'dobra_1',
        section_name: `${title ? title.textContent : 'Peça seu cartão sem anuidade'}`,
        element_action: 'submit',
        element_name: 'Continuar',
        redirect_url: 'null',
        })

        setIsPopupOpen(true)
        setStatus(formStatus.sent)
    } catch (e) {
      setIsPopupOpen(true)
      setStatus(formStatus.error)
    }
  }

  const formRespondeModal = isDomReady && (
    <Modal isModalOpen={isPopupOpen} setIsModalOpen={setIsPopupOpen} locationToRender={document.body}>
      {status === formStatus.sent && (
        <Sent closeButton={closeButton} closePopup={handleClose} language={language.sent} />
    )}
      {status === formStatus.filled && (
        <Filled closeButton={closeButton} closePopup={handleClose} language={language.sent} />
    )}
      {status === formStatus.error && (
        <Error closeButton={closeButton} closePopup={handleClose} language={language.error} />
    )}
    </Modal>
  )

  return (
    <>
      {formRespondeModal}
      <Container
        className='d-flex align-items-center'
      >
        <div className='row mx-0'>
          <div className='col-12'>
            <form
              className='form--default'
              name='open_account'
              onSubmit={handleSubmit(sendForm)}
            >
              <div className='row mt-3'>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.name] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.name} className='fs-14 lh-18 d-block'>
                    {language.nameInput.label}
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: language.nameInput.errorMessage,
                      validate: {
                        isName: (value: string) =>
                          Validations.name(value) ||
                          language.nameInput.errorMessage,
                      },
                    })}
                    name={FormFields.name}
                    id={FormFields.name}
                    type='text'
                    maxLength={100}
                    placeholder={language.nameInput.placeholder}
                  />
                  {errors[FormFields.name] && (
                    <p className='fs-12 text-right mb-0'>
                      {language.nameInput.errorMessage}
                    </p>
                  )}
                </div>
                <div
                  className={`col-12 pb-3 px-0 ${errors[FormFields.email] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.email} className='fs-14 lh-18 d-block'>
                    {language.emailInput.label}
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: language.emailInput.errorMessage,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: language.emailInput.errorMessage,
                      },
                    })}
                    name={FormFields.email}
                    id={FormFields.email}
                    type='text'
                    maxLength={100}
                    placeholder={language.emailInput.placeholder}
                  />
                  {errors[FormFields.email] && (
                    <p className='fs-12 text-right mb-0'>
                      {language.emailInput.errorMessage}
                    </p>
                  )}
                </div>
                <PrefixDisabledInputContainer
                  className='col-3 pb-3 px-0 pr-md-3'
                >
                  <label className='fs-14 lh-18 prefix-label d-block'>
                    {language.prefixInput.label}
                  </label>
                  <input
                    className='w-100'
                    disabled
                    name='prefix'
                    id='prefix'
                    type='text'
                    placeholder={language.prefixInput.placeholder}
                    maxLength={15}
                  />
                  <div className='icon-div'>
                    <Security color={grayscale[300]} width={24} height={24} />
                  </div>
                </PrefixDisabledInputContainer>
                <div
                  className={`col-8 col-md-9 offset-1 offset-md-0 pb-3 px-0 ${errors[FormFields.telefone] &&
                    'input-error'}`}
                >
                  <label htmlFor={FormFields.telefone} className='fs-14 lh-18 d-block'>
                    {language.phoneInput.label}
                  </label>
                  <input
                    className='w-100'
                    ref={register({
                      required: language.phoneInput.errorMessage,
                      pattern: {
                        value: /^\(\d{3}\) \d{3}-\d{4}$/,
                        message: language.phoneInput.errorMessage,
                      },
                    })}
                    name={FormFields.telefone}
                    id={FormFields.telefone}
                    type='tel'
                    placeholder='(000) 000-0000'
                    maxLength={15}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setValue(
                        FormFields.telefone,
                        AmericanPhoneMask(event.currentTarget.value),
                    )}
                  />
                  {errors[FormFields.telefone] && (
                    <p className='fs-12 text-right mb-0'>
                      {language.phoneInput.errorMessage}
                    </p>
                  )}
                </div>
                <div className='col-12 d-flex flex-column pt-2 pt-md-0 px-0 mt-md-n2 mt-lg-n1 mt-xl-2'>
                  <Checkbox className='m-0'>
                    <input
                      name='aceite'
                      id='aceite'
                      type='checkbox'
                      className='form-input-check'
                      onChange={() => setAccept(!accept)}
                    />
                    <label className='form-label-check text-grayscale--500 fs-16 lh-20 fw-400' htmlFor='aceite' dangerouslySetInnerHTML={{ __html: language.acceptTerms }} />
                  </Checkbox>
                </div>
                <div className='col-12 px-0 text-center mt-2 pt-lg-2'>
                  <WhatsappGreenButton
                    type='submit'
                    title={language.submitButton.text}
                    disabled={!accept || status === formStatus.loading}
                    className='mx-auto text-none'
                  >
                    {status === formStatus.loading
                      ? language.submitButton.loading
                      : language.submitButton.text}
                  </WhatsappGreenButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </>
  )
}

export default FormularioInvestments
