import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const FaqStyledSection = styled.section`
  section {
    padding: 0;
    @media ${device.tablet} {
      padding: 0;
    }
    @media ${device.desktopLG} {
      padding: 0;
    }
    @media ${device.desktopXL} {
      padding: 0;
    }
  }

  p {
    color: ${grayscale[400]};
  }
`
