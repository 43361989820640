import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray, grayscale, white } from 'src/styles/colors'

export const Section = styled.section`
  background-color: ${white};

  @media ${device.tablet} {
    background-color: ${gray[400]};
  }
`

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  justify-content: center;

  .card-div {
    padding: 26px;
    background-color: ${gray[400]};
    border: 1px solid ${grayscale[200]};
    height: 176px;

    @media ${device.tablet} {
      background-color: ${white};
      width: 220px;
      height: 192px;
    }

    @media ${device.desktopLG} {
      width: 296px;
      height: 176px;
    }

    @media ${device.desktopXL} {
      width: 360px;
      height: 168px;
    }

    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
