import React, { ReactNode } from 'react'

import { grayscale } from 'src/styles/colors'

import textPT from '../../assets/data/_sua-vida-financeira/textPT.json'
import textEN from '../../assets/data/_sua-vida-financeira/textEN.json'

import CallCredits from 'inter-frontend-svgs/lib/orangeds/MD/call-credits'
import Exchange from 'inter-frontend-svgs/lib/orangeds/MD/exchange'
import CardIcon from 'inter-frontend-svgs/lib/orangeds/MD/card'

import * as S from './style'

interface IIconsList {
  [index: string]: ReactNode;
}

const SuaVidaFinanceira = (props: {lang: string}) => {
  const pageText = props.lang === 'pt' ? textPT : textEN

  const icons: IIconsList = {
    callCredits: <CallCredits color={grayscale[400]} width={32} height={32} />,
    exchange: <Exchange color={grayscale[400]} width={32} height={32} />,
    card: <CardIcon color={grayscale[400]} width={32} height={32} />,
  }

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 f-sora text-grayscale--500 fw-400 text-center mb-4' dangerouslySetInnerHTML={{ __html: pageText.title }} />
          </div>
          <div className='col-12'>
            <S.CardsWrapper>
              {pageText.cards.map((card: { icon: string; description: string }) => (
                <div className='card-div' key={card.icon}>
                  <div>
                    {icons[card.icon]}
                  </div>
                  <div>
                    <p className='fs-16 lh-20 fs-xl-20 lh-xl-26 fw-600 f-sora text-grayscale--500 mb-0' dangerouslySetInnerHTML={{ __html: card.description }} />
                  </div>
                </div>
                ))}
            </S.CardsWrapper>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default SuaVidaFinanceira
