import styled from 'styled-components'
import { white, grayscale, orange, gray } from 'src/styles/colors'
import { breakpoints, device } from 'src/styles/breakpoints'

type imageProps = {
  width?: number;
}

export const Container = styled.div`
  background: ${white};
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  padding: 24px;
  border-radius: 16px;
  border: 1px solid ${grayscale[200]};

  @media (min-width: ${breakpoints.md}) {
    max-width: 596px;
    padding: 24px;
  }

  h1, h2, h3 {
    font-family: Sora;
  }

  input {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    &::placeholder {
      font-family: 'Sora';
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: ${grayscale[300]};
    }
  }

  label {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 18px;
    color: ${grayscale[500]};
  }

  .prefix-label {
    color: ${grayscale[400]} !important;
  }

  .back {
    display: initial;
    @media (min-width: 1601px) {
      display: block;
    }
  }

  button[type="submit"] {
    height: 48px;
    width: 100%;
    max-width: 100%;

    &:disabled {
      background: ${grayscale['200']};
      color: ${grayscale['300']} !important;
    }
  }

  .form-label-check {
    &:before {
      top: 34px;
      @media ${device.tablet} {
        top: 8px;
      }
      @media ${device.desktopLG} {
        top: 17px;
      }
      @media ${device.desktopXXXL} {
        top: 2px;
      }
    }
  }

  .mt-mobile {
    margin-top: 14px;
  }

  .form-input-check {
    &:checked ~ label {   
    &::after {
      top: 37px;  
        @media ${device.tablet} {
          top: 11px;
        } 
        @media ${device.desktopLG} {
          top: 20px;
        }
        @media ${device.desktopXL} {
          top: 20px;
        }
        @media ${device.desktopXXXL} {
          top: 5px;
        }  
      }
    }
  }

  .input-error {
    label, p {
      color: #F56A50;
    }
    input {
      background-color: #FEF0ED;
    }
  }

  #qrCodeContainer canvas { 
    height: 200px;
    width: 200px;
  }

  span.select {
    background: ${grayscale[100]};
    border-radius: 8px;
    display: block;
    position: relative;
    outline: none;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0;
    height: 48px !important;
    &::placeholder {
      font-family: 'Sora';
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: ${grayscale[300]};
    }
    .ativo{
      color: ${grayscale[500]};
    }
    &:after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 14px;
      height: 14px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
      right: 30px;
      top: 14px;
      color: ${orange.extra}
    }
    select {
      appearance: none;
      background: ${grayscale[100]};
      border: 0;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      line-height: 17px;
      font-weight:700;
      padding: 16px;
      width: 100%;
      color:${grayscale[300]};
      text-overflow: ellipsis;
      padding-right: 50px;
      white-space: nowrap;
      :focus{
      outline:none;
      }
    }
  }
`
export const Image = styled.img`
  width: ${(props: imageProps) => props.width ? `${props.width}px` : '104px'};
  height: auto;
`
export const Checkbox = styled.div`
  margin: 20px 0;
  input {
    display: block;
  }
  .form-label-check {
    position: relative;
    padding-left: 30px;
    margin-bottom: 0;

    &::before, &::after {
      display: block;
      position: absolute;
      content: '';
    }
    &::before {
      border: 2px solid ${grayscale[300]};
      border-radius: 3px;
      height: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
    }
    a {
      &:hover {
        color: ${gray['600']};
      }
    }
  }
  .form-input-check {
    height: 0 !important;
    opacity: 0;
    width: auto;
    &:disabled ~ label {
      opacity: 0.6;
    }
    &:checked ~ label {
    &::before {
      background: ${orange.extra};
      border: 1px solid ${orange.extra};
    }
    &::after {
      left: 4px;
      top: 42%;
      width: 6px;
      height: 10px;
      border: solid ${white};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translateY(-50%);
      }
    }
  }
`

export const CloseButton = styled.button`
  @media (max-width: ${breakpoints.sm}) {
    right: 10px;
  }

  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;

  &:focus{
    outline: none;
  }
`

export const WhatsappGreenButton = styled.button`
  height: 48px;
  background-color: #FF7A00;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${white};
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 8px;
  border: none;

  :hover {
    transition: 400ms ease-in-out;
    opacity: 0.8;
  }
`

export const PrefixDisabledInputContainer = styled.div`
  position: relative;

  .icon-div {
    position: absolute;

    right: 7px;
    top: 30px;

    @media ${device.tablet} {
      right: 22px;
      top: 30px;
    }

    @media ${device.desktopXL} {
      right: 30px;
      top: 30px;
    }
  }
`
