import React, { ReactElement } from 'react'

import CheckCircle from '@interco/icons/build-v4/orangeds/MD/check-circle'

import { Container, WhatsappGreenButton } from '../../style'
import styled from 'styled-components'

interface IFormResponseLang {
  title: string;
  descrpition: string;
  confirm: string;
}

type OpenAccountFormProps = {
  closeButton: ReactElement;
  closePopup: () => void;
  language: IFormResponseLang;
};

type Circle = {
  circleColor: string;
}

const Filled = ({
  closeButton,
  closePopup,
  language,
}: OpenAccountFormProps) => {
  return (
    <Container style={{ height: 420 }} className='mx-4 mx-md-0'>
      <div className='text-center mt-5'>
        {closeButton}
        <Circle className='mx-auto' circleColor='#00AA4F'>
          <CheckCircle height={32} width={32} color='#ffffff' />
        </Circle>
        <h3 className='fs-20 lh-25 text-grayscale--500'>{language.title}</h3>
        <p className='fs-14 lh-17 text-grayscale--500 mt-4'>
          {language.descrpition}
        </p>
        <div className='col-12'>
          <WhatsappGreenButton
            onClick={closePopup}
            title={language.confirm}
            className='mx-auto px-5 mt-5 text-none'
          >
            {language.confirm}
          </WhatsappGreenButton>
        </div>
      </div>
    </Container>
  )
}

export const Circle = styled.div<Circle>`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: ${(props: Circle) => props.circleColor};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

export default Filled
