export enum FormFields {
  name = 'name',
  email = 'email',
  telefone = 'telefone',
}

export interface IFormValues {
  name: FormFields.name;
  email: FormFields.email;
  telefone: FormFields.telefone;
}

export type UtmStateType = string | string[] | null | undefined

export type SelectOptionsType = {
  name: string;
  value: string;
}
