import styled from 'styled-components'
import { gray } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'
import BackgroundImageMD from '../../assets/images/hero-background-investments-md.webp'
import BackgroundImageLG from '../../assets/images/hero-background-investments-lg.webp'
import BackgroundImageXL from '../../assets/images/hero-background-investments-xl.webp'
import BackgroundImageXXXL from '../../assets/images/hero-background-investments-xxxl.webp'

export const HeroSection = styled.section`
  min-height: calc(100vh - 64px);
  background-color: ${gray[400]};

  @media ${device.tablet} {
    background-image: url(${BackgroundImageMD});
    background-size: cover;
    min-height: 500px;
  }

  @media ${device.desktopLG} {
    background-image: url(${BackgroundImageLG});
    min-height: calc(100vh - 0px);
  }

  @media ${device.desktopXL} {
    background-image: url(${BackgroundImageXL});
  }

  @media ${device.desktopXXXL} {
    background-image: url(${BackgroundImageXXXL});
  }
`
